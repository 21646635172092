// Bootstrap functions
@import "node_modules/bootstrap/scss/functions";

// Custom variables
@import 'variables';

// Dashkit variables
@import 'dashkit/variables';

// Bootstrap core
@import 'node_modules/bootstrap/scss/bootstrap';

// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
@import 'node_modules/@fortawesome/fontawesome-svg-core/styles.css';

// third party imports
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/ngx-sharebuttons/themes/default";

@import 'dashkit/variables';

// Theme core
@import 'dashkit/theme';

// Vendors
@import 'ng-select';
@import 'dropzone-reset';
@import 'vime';
@import 'brands';
@import 'ks-gallery';

// Custom core
@import 'cdk';
@import 'navbar';
@import 'avatar';
@import 'tag';
@import 'dropdown';
@import 'recaptcha';
@import 'pagination';

// Material Theme
@import 'material';
