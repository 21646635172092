.dropdown-ellipses {
  color: var(--bs-body-color);
  display: inline-block;
  padding: 0 10px;
}

.ng-dropdown-panel {
  z-index: 1056 !important;
}

.ng-dropdown-panel.auto-grow {
  width: auto !important;
}
