@import "mixins/tag";

$grey-300: #f3f1f1;
$blue-300: #2C7BE5;
$blue-700: #061a62;

$green-300: #6DF4DC;
$brand-100: #0A3852;

$text-muted: var(--bs-gray-800);

$white: #fff;
$blue: #061a62;
$light-blue: $blue-300;
$green: #00D97E !default;

$primary: $green-300 !default;
$success: $green !default;
$border-radius: 5px;

$youtube-color: #FF0000;
$dailymotion-color: #00aaff;
$vimeo-color: #86c9ef;

$body-bg: white;

$shadow-grey: #e9e9e9;
$light-grey : #bbbbbb;
$font-grey : $brand-100 !default;
$dark-blue: #4659A8;
$light-blue: #f0f5fb;
$color-yellow: #fed474;
$font-blue: #071A63;
$color-info: #3EB1EF;

$staticFolder : '/assets';
$asset-base-path: '/assets/' !default;

$body-font-size: 16px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1350px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px
) !default;

@function asset($file) {
  @return url($asset-base-path + $file);
}

@function bs-var($variable) {
  @return var(--#{$prefix}#{$variable});
}

