@import "src/scss/mixins/bootstrap";

@include media-breakpoint-down(md) {
  .pagination-container {
    flex-wrap: wrap;
    width: 100%;

    .list-pagination {
      order: 0;
      width: 100%;
      justify-content: space-between;
    }

    .list-pagination-prev {
      order: 1;
    }

    .list-pagination-next {
      order: 2;
    }
  }
}
